import { render, staticRenderFns } from "./_postsTabs.vue?vue&type=template&id=fdb36a0e&scoped=true"
import script from "./_postsTabs.vue?vue&type=script&lang=js"
export * from "./_postsTabs.vue?vue&type=script&lang=js"
import style0 from "./_postsTabs.vue?vue&type=style&index=0&id=fdb36a0e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fdb36a0e",
  null
  
)

export default component.exports