<template>
  <div class="task-posts-tabs">
    <div class="tabs has-margin-bottom-100">
      <ul>
        <li
          v-for="(tab, index) in filteredTabs"
          :key="`task-posts-tab-${index}`"
          :class="{ 'is-active': activeTab === index }"
        >
          <a @click="activeTab = index">
            {{ tab.label }}
            <span v-if="tab.count" class="pill">{{ tab.count }}</span>
          </a>
        </li>
        <transition name="fade">
          <li
            v-if="$task.siteHasWordpressPlugin && activeTab === 0"
            class="has-padding-left-150"
            :style="{ marginLeft: 'auto' }"
          >
            <b-switch
              v-model="showSiteChanges"
              @input="
                $store.dispatch('ui/setLocalPref', {
                  showSiteChanges
                })
              "
            >
              <span>Show site changes</span>
            </b-switch>
          </li>
        </transition>
      </ul>
    </div>

    <task-posts
      :key="`task-posts-${activeTab}`"
      v-bind="filteredTabs[activeTab].props"
      :task-id="taskId"
    />
  </div>
</template>

<script>
export default {
  name: "TaskPostsTabs",
  components: {
    "task-posts": () => import("@shared/tasks/posts/_posts")
  },
  inject: ["$taskProvider"],
  props: {
    taskId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      activeTab: 0,
      showSiteChanges: false
    };
  },
  computed: {
    $task() {
      return this.$taskProvider();
    },
    userIsReseller() {
      return this.$store.getters["user/isReseller"]();
    },
    userIsAgent() {
      return this.$store.getters["user/isAgent"]();
    },
    filesCount() {
      let total = this.$_.get(this.$task, "stats.totalAttachments", 0);
      if (!this.userIsAgent && this.userIsReseller)
        total =
          total - this.$_.get(this.$task, "stats.totalInternalAttachments", 0);
      if (!this.userIsAgent && !this.userIsReseller)
        total =
          total - this.$_.get(this.$task, "stats.totalPrivateAttachments", 0);
      return total;
    },
    tabs() {
      return [
        {
          filter: true,
          label: "All",
          props: {
            includePrivate:
              this.userIsReseller || this.userIsAgent ? null : false,
            includeInternal: this.userIsAgent ? null : false,
            includeLogs: true,
            includeSiteChanges: this.showSiteChanges
          }
        },
        {
          filter:
            this.$task.hasResellerOrigins &&
            (this.userIsReseller || this.userIsAgent),
          label: `Private messages`,
          count: this.$_.get(this.$task, "stats.totalPrivatePosts", 0),
          props: {
            includePrivate: true,
            includeLogs: false
          }
        },
        {
          filter: this.userIsAgent,
          label: "Internal notes",
          count: this.$_.get(this.$task, "stats.totalInternalPosts", 0),
          props: {
            includeInternal: true,
            includeLogs: false
          }
        },
        {
          filter: true,
          label: "Files",
          count: this.filesCount,
          props: {
            includePrivate:
              this.userIsReseller || this.userIsAgent ? null : false,
            includeInternal: this.userIsAgent ? null : false,
            includeFiles: true,
            includeLogs: false
          }
        },
        {
          filter: this.$task.site && this.$task.siteHasWordpressPlugin,
          label: "Site changes",
          props: {
            includeMessages: false,
            includeLogs: false,
            includeSiteChanges: true,
            paginateSiteChanges: true
          }
        },
        {
          filter: true,
          label: "Logs",
          props: {
            includeLogs: true,
            includeMessages: false
          }
        }
      ];
    },
    filteredTabs() {
      return this.$_.filter(this.tabs, tab => tab.filter);
    }
  },
  async created() {
    this.showSiteChanges = !!(await this.$store.dispatch("ui/getLocalPref", {
      key: "showSiteChanges",
      fallback: true
    }));
  }
};
</script>

<style lang="scss" scoped>
@import "~@sass/bulma/custom-variables";
.task-posts-tabs {
  @include tablet {
    margin: 0 0 0 3.5rem;
  }
  .tabs {
    a {
      padding: 0.75em;
      .pill {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 1.5rem;
        min-width: 1.5rem;
        padding: 0 0.5rem;
        font-size: 11px;
        font-weight: 600;
        margin-left: 0.5em;
        border-radius: 50%;
        background: $grey-lighter;
        color: $dark;
      }
    }
  }
}
</style>
